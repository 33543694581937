


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {modifyOrderCourseTime} from '@/api/courseOrder'

@Component({})
export default class PtFreeClass extends Vue {
  @Prop({default: () => false}) private show!: boolean
  @Prop({default: () => ''}) private orderId!: string

  private currentShow = this.show
  private currentOrderId = ''
  private courseTimeValue = ''
  private pShow = false

  @Watch('orderId', {immediate: true})
  public getOrderId(vl: string, ov: string) {
    if (vl !== ov) {
      this.currentOrderId = vl
    }
  }

  private async handleConfirm() {
    if (!this.courseTimeValue) {
      this.$toast('请输入赠送课时')
      return false
    }

    if (this.currentOrderId) {
      await modifyOrderCourseTime(this.currentOrderId, this.courseTimeValue)
      this.handleClose()
    }
  }

  private handleBeforeClose(action: any, done: any) {
    done(false)
    if (action !== 'confirm') {
      this.handleClose()
    }
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
