
















































import {Component, Vue} from 'vue-property-decorator'
import {getPtByCoachOrders} from '@/api/courseOrder'

import CourseTime from '@/components/CourseTime.vue'
import PtFreeClass from './components/PtFreeClass.vue'
import PtSignIn from './components/PtSignInList.vue'

import RecordApi from '@/api/courseOrderRecord'

@Component({
  components: {
    CourseTime,
    PtFreeClass,
    PtSignIn
  }
})
export default class PtOrder extends Vue {
  private api = new RecordApi()
  private actionShow = false
  private orders = new Array()
  private actions = [{name: '赠课'}, {name: '上课签到'}, {name: '签到记录'}]

  private courseTimeShow = false
  private courseSignIn = false
  private orderId = ''

  private async activated() {
    await this.getList()
  }

  private async getList() {
    this.orders = await getPtByCoachOrders()
  }

  private handleClickCZ(item: any) {
    this.actionShow = true
    this.orderId = item.id
  }

  private async handleSelect(action: any, index: number) {
    if (action.name === '赠课') {
      this.courseTimeShow = true
    } else if (action.name === '上课签到') {
      await this.handleSignIn()
    } else if (action.name === '签到记录') {
      this.courseSignIn = true
    }
  }

  private async handleSignIn() {
    if (this.orderId) {
      await this.api.orderSignInAsync(this.orderId);
      this.$toast('签到成功', {type: 'success'})
      await this.getList()
    }
  }

  private async handleClose() {
    this.courseTimeShow = false
    this.courseSignIn = false
    await this.getList()
  }
}
